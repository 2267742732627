<template>
  <b-card :variant="`danger`" class="border-warning">
    <b-row>
      <b-col v-if="product.productCategoryId" cols="12" md="4">
        <b-form-group label="Choose Category Group">
          <v-select
              v-model="property.categoryPropertyGroupId"
              :options="CPG"
              :reduce="(item) => item.id"
              label="name"
              placeholder="Choose your desired Category Group"
          >
            <template #no-options>There is no Category Group available</template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col v-if="product.properties[idx].categoryPropertyGroupId" cols="12" md="4">
        <b-form-group label="Choose Category Property">
          <v-select
              v-model="property.categoryPropertyId"
              :options="properties"
              :reduce="(item) => item.id"
              label="name"
              placeholder="Choose your desired Category Property"
          >
            <template #no-options>There is no Category Group available</template>
          </v-select>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group
            class="mb-2"
            label="Value"
        >
          <b-form-input
              v-model="property.value"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group
            class="mb-2"
            label="Priority"
        >
          <b-form-input
              v-model="property.priority"
          ></b-form-input>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-form-group
            class="mb-2"
            label="Show in content"
        >
          <b-form-checkbox
              v-model="property.showInContent"
              switch
          >

          </b-form-checkbox>
        </b-form-group>
      </b-col>

      <b-col cols="12" md="4">
        <b-button
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            class="col-12 col-md-4 mt-2"
            variant="danger"
            @click="deleteProperty"
        >
          Delete property
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm, BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {required} from '@validations';
import {
  CategoryPropertyGetBy,
  CategoryPropertyGroupGetBy,
  ProductCategoriesGetSubCategories,
} from "@/libs/Api/ProductCategory";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import {GetAllLangs} from "@/libs/Api/Language";
import {ShipmentGetAll} from "@/libs/Api/Shipment";
import ckEditorMain from "@/views/components/ckEditorMain.vue";

export default {
  data(){
    return{
      properties:[],
    }
  },
  props:[
    'product',
    'property',
    'idx',
    'CPG',
  ],
  watch: {
    'property.categoryPropertyGroupId': function (nv, ov) {
      this.getProperties();
    },
  },
  methods:{
    deleteProperty(){
      this.product.properties.splice(this.idx,1)
    },
    async getProperties() {
      let _this = this;
      _this.overlay = true;

      let data = {
        CategoryPropertyGroupId:_this.property.categoryPropertyGroupId,
      }

      let categoryPropertyGetBy = new CategoryPropertyGetBy(_this);
      categoryPropertyGetBy.setParams(data);
      await categoryPropertyGetBy.fetch(function (content) {
        _this.overlay = false;
        _this.properties = content.data.data;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
  },
  components: {
    BFormCheckbox,
    ckEditorMain,
    AddEditDeleteButton,
    LanguagePicker,
    BModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BTable,
    BPagination,
    ValidationObserver, ValidationProvider,
  },
}
</script>

<style scoped>

</style>