import WebServiceRequest from "../Api/WebServiceRequest";
class UnitsCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Units/Create");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

class UnitsDelete extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod('DELETE')
    }
    setParams(data) {
        super.setTag(`Units/DeleteById/${data.id}`);
    }
}

class UnitsUpdate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Units/Update");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

class UnitsGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Units/GetAll");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class UnitsGetBy extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod('GET')
        super.setTag("Units/GetBy");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    UnitsCreate,
    UnitsDelete,
    UnitsUpdate,
    UnitsGetAll,
    UnitsGetBy
}