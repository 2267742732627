import WebServiceRequest from '../Api/WebServiceRequest'

class OrderChangeStatusRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setId(data){
        super.setTag('Orders/ChangeOrderStatus?orderId='+data.orderId+'&status='+data.status)
    }
}
class OrderCreateByAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/CreateByAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderCreateRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/Create')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderCreateForAdminRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/CreateForAdmin')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
class OrderDeleteRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/Delete')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class OrderGetAllRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setMethod("GET")
        super.setTag('Orders/GetAllForAdmin')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class OrderGetByUserIdRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/GetByUserId')
    }
}
class OrderGetProductsForStudents extends WebServiceRequest {
    constructor (context) {
        super(context)
    }
    setParams(data){
        super.setTag('Orders/GetProductsForStudents?searchCommand='+data.searchCommand+'&pageNumber='+data.pageNumber+'&count='+data.count)
    }
}
class OrderGetRegisteredUserRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/GetRegisteredUser')
    }
    setParams(data){
       super.setRequestParam(data)
    }
}
class OrderGetRequest extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/GetBy')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}

class OrderOrderPaymentToggle extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/OrderPaymentToggle')
    }
    setParams(data){
        super.setRequestParam(data)
    }
}
class OrderSortingByAdminMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/SortingByAdminMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderPacketingByAdminMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/PacketingByAdminMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderPreparingMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/PreparingMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderShippingFromOriginToDestinationMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/ShippingFromOriginToDestinationMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderShippingCompletedMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/ShippingCompletedMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderUpdateShippingFromOrginToDestinationMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/UpdateShippingFromOrginToDestinationMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderUpdateShippingCompletedMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/UpdateShippingCompletedMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderCloseOrderMilestone extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/CloseOrderMilestone')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class OrderUpdateMilestoneByAdmin extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('Orders/UpdateMilestoneByAdmin')
    }
    setParams(data){
        super.setFormData(data)
    }
}
class LiveStreamCreateOrderLiveStream extends WebServiceRequest {
    constructor (context) {
        super(context)
        super.setTag('LiveStream/CreateOrderLiveStream')
    }
    setParams(data){
        super.setRequestParamDataObj(data)
    }
}
export {
    OrderChangeStatusRequest,
    OrderCreateRequest,
    OrderCreateByAdmin,
    OrderDeleteRequest,
    OrderGetAllRequest,
    OrderGetByUserIdRequest,
    OrderGetProductsForStudents,
    OrderGetRegisteredUserRequest,
    OrderGetRequest,
    OrderCreateForAdminRequest,
    OrderOrderPaymentToggle,
    OrderSortingByAdminMilestone,
    OrderPacketingByAdminMilestone,
    OrderPreparingMilestone,
    OrderShippingFromOriginToDestinationMilestone,
    OrderShippingCompletedMilestone,
    OrderCloseOrderMilestone,
    OrderUpdateShippingFromOrginToDestinationMilestone,
    OrderUpdateShippingCompletedMilestone,
    OrderUpdateMilestoneByAdmin,
    LiveStreamCreateOrderLiveStream
};
