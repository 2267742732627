import {CreateFile} from "@/libs/Api/Media";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import Helper from "@/libs/Helper";

export default class UploadAdapter {
    constructor(loader,type) {
        // The file loader instance to use during the upload.
        this.loader = loader;
        this.type = type;
    }

    async sendCreateFileRequest(file,type) {
        let result = '';
        try {
            let _this = this;
            let formData = new FormData;
            let upFile = await file;
            let data = {
                base64:upFile,
                type:1,
            }
            let createFile = new CreateFile(_this);
            createFile.setParams(formData);
            await createFile.fetch((content) => {
                result = content.value;
            });
        } catch (error) {
            console.error(error);
        }
        return result;
    }

    // Starts the upload process.
    async upload() {
        return new Promise(async (resolve, reject) => {
            let pictureUrl = await this.sendCreateFileRequest(this.loader.file,this.type);
            resolve({default: pictureUrl});
        });
    }

    // Aborts the upload process.
    abort() {
        //
    }
}
