import WebServiceRequest from "../Api/WebServiceRequest";
class ShipmentCreate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Shipment/Create");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

class ShipmentDelete extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod('DELETE')
    }
    setParams(data) {
        super.setTag(`Shipment/DeleteById/${data.id}`);
    }
}

class ShipmentUpdate extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setTag("Shipment/Update");
    }
    setParams(data) {
        super.setRequestParamDataObj(data);
    }
}

class ShipmentGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod('GET')
        super.setTag("Shipment/GetAll");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

class ShipmentGetBy extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod('GET')
        super.setTag("Shipment/GetBy");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    ShipmentCreate,
    ShipmentDelete,
    ShipmentUpdate,
    ShipmentGetAll,
    ShipmentGetBy
}