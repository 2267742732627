import WebServiceRequest from "../Api/WebServiceRequest";
class CurrenciesGetAll extends WebServiceRequest {
    constructor(context) {
        super(context);
        super.setMethod("GET")
        super.setTag("Currencies/GetAll");
    }
    setParams(data) {
        super.setRequestParam(data);
    }
}

export {
    CurrenciesGetAll
}