<template>
  <b-overlay :show="isLoading" rounded="sm">
    <b-row>
      <b-col cols="12">
        <b-form class="w-100" @submit.prevent>
          <b-form-group :label="placeholder" label-for="language-picker">
            <v-select
              @search="handleSearch"
              id="language-picker"
              :filterable="false"
              v-if="languageList"
              v-model="selectedLanguage"
              :options="languageList"
              label="name"
              placeholder="Choose Language"
            >
              <template #no-options>No Language Available</template>
            </v-select>
          </b-form-group>
        </b-form>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import { GetAllLangs } from "@/libs/Api/Language";
import vSelect from "vue-select";
import { BOverlay, BRow, BCol, BForm, BFormGroup } from "bootstrap-vue";

export default {
  emits: ["getLanguage"],
  props: {
    defaultLanguageId: {
      type: [String, Number],
      required: false,
    },
    placeholder: {
      type: String,
      required: false,
      default: "Select desired language",
    },
  },
  data() {
    return {
      isLoading: false,
      languageList: null,
      searchCommand: null,
      timeout: null,
      selectedLanguage: null,
    };
  },
  async mounted() {
    await this.getAllLanguages();
    if (this.defaultLanguageId) {
      let selectedLang = this.languageList.find(
        (item) => item.languageId == this.defaultLanguageId
      );
      if (selectedLang) {
        this.selectedLanguage = selectedLang;
      }
    }
  },
  watch: {
    selectedLanguage: {
      handler(val) {
        this.$emit("getLanguage", val);
      },
    },
  },
  methods: {
    handleSearch(searchValue, loadingFn) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(async () => {
        this.searchCommand = searchValue;
        loadingFn(true);
        await this.getAllLanguages();
        loadingFn(false);
      }, 300);
    },
    async getAllLanguages() {
      try {
        this.isLoading = true;
        let reqParams = {
          count: 0,
          pageNumber: 0,
        };
        if (this.searchCommand && this.searchCommand != "") {
          reqParams["searchCommand"] = this.searchCommand;
        }
        let _this = this;
        let getAllLangs = new GetAllLangs(_this);
        getAllLangs.setRequestParam(reqParams);
        await getAllLangs.fetch((response) => {
          if (response.isSuccess) {
            _this.languageList = response.data.languages;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  components: {
    vSelect,
    BOverlay,
    BRow,
    BCol,
    BForm,
    BFormGroup,
  },
};
</script>
