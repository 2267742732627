<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <b-row>
      <b-col cols="12">

        <b-card>
            <h3 class="text-primary mb-2">
              Select products
            </h3>
            <b-table
                class="position-relative"
                :items="productsList"
                responsive
                :fields="myTableColumns"
                primary-key="id"
                show-empty
                bordered
                striped
                empty-text="No Products were found"
            >
              <!-- eslint-disable-next-line -->
              <template #cell(cover)="data">
                <b-media vertical-align="center">
                  <b-avatar
                      rounded
                      size="100"
                      :src="`${baseUrl}/${data.item.image.pictureUrl}`"
                      :variant="`success`"
                  />
                </b-media>
              </template>

              <template #cell(prices)="data">
                {{ data.item.prices.price }} {{ data.item.prices.currency }}
              </template>

              <template #cell(add)="data">
                <feather-icon @click="addProductToOrder(data.item)" icon="PlusSquareIcon" size="20" class="text-primary cursor-pointer"/>
              </template>
            </b-table>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-center py-2"
            >
              <b-pagination
                  v-model="pageNumber"
                  :total-rows="totalCount"
                  :per-page="count"
                  first-number
                  last-number
                  class="mb-0 mt-1"
                  prev-class="prev-item"
                  next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                  />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-card>

        <b-row>
          <b-col cols="12">
            <b-card class="col-12" v-for="(item,idx) in order.orderItems" :key="idx">
              <b-row>
                <h4 class="text-primary col-12">{{ item.productName }}</h4>

                <b-col cols="12" md="4">
                  <b-form-group label="Choose Unit" label-for="blog-category">
                    <v-select
                        v-if="units"
                        id="blog-category"
                        v-model="item.unitId"
                        :options="units"
                        :reduce="(unit) => unit.id"
                        label="name"
                        placeholder="Choose your desired category"
                    >
                      <template #no-options>There is no Unit available</template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                  <b-form-group label="Choose currency Shipments" label-for="shipments">
                    <v-select
                        v-if="currencies"
                        id="shipments"
                        v-model="item.currencyId"
                        :options="currencies"
                        :reduce="(title) => title.id"
                        label="title"
                        placeholder="Choose your desired shipments"
                    >
                      <template #no-options>There is no currency available</template>
                    </v-select>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                  <b-form-group
                      class="mb-2"
                      label="Quantity"
                  >
                    <b-form-input
                        v-model="item.quantity"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                  <b-form-group
                      class="mb-2"
                      label="Unit Price"
                  >
                    <b-form-input
                        v-model="item.unitPrice"
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <b-col cols="12" md="4">
                  <b-form-group
                      class="mb-2"
                      label="Off Price"
                  >
                    <b-form-input
                        v-model="item.offPrice"
                    ></b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-card>
          </b-col>

          <b-col v-if="order.orderItems.length > 0" cols="12">
            <b-card>
              <b-row>
                <b-col cols="12">
                  <b-button
                      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                      class="col-12 col-md-2"
                      variant="primary"
                      @click="createOrder"
                  >
                    Save order
                  </b-button>
                </b-col>
              </b-row>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-overlay>
</template>

<script>
import {
  BAlert,
  BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BImg,
  BLink,
  BMedia,
  BModal,
  BOverlay,
  BPagination,
  BRow,
  BTab,
  BTable,
  BTabs
} from 'bootstrap-vue'
import vSelect from "vue-select";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import Helper from "@/libs/Helper";
import {required} from '@validations';
import {CategoryPropertyGroupGetBy, ProductCategoriesGetSubCategories,} from "@/libs/Api/ProductCategory";
import LanguagePicker from "@/views/components/utils/LanguagePicker.vue";
import AddEditDeleteButton from "@/views/components/utils/AddEditDeleteButton.vue";
import {GetAllLangs} from "@/libs/Api/Language";
import {ShipmentGetAll} from "@/libs/Api/Shipment";
import ckEditorMain from "@/views/components/ckEditorMain.vue";
import ProductCategoryProperty from "@/views/apps/product/ProductCategoryProperty.vue";
import {ProductCreate, ProductGetAll} from "@/libs/Api/Product";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import {UnitsGetAll} from "@/libs/Api/Units";
import {CurrenciesCreate, CurrenciesGetAll} from "@/libs/Api/Currencies";
import {OrderCreateByAdmin} from "@/libs/Api/Order";

export default {
  title: 'Create order',
  data() {
    return {
      required,
      baseUrl:Helper.baseUrl,
      languageList: [],
      overlay: false,
      categories: [],
      productsList: null,
      count: 10,
      pageNumber: 1,
      totalCount: null,
      myTableColumns: [
        {
          key: "cover",
          label: "Cover",
        },
        {
          key: "name",
          label: "Title",
        },
        {
          key: "subTitle",
          label: "subTitle",
        },
        {
          key: "prices",
          label: "Last price",
        },
        {
          key: "add",
          label: "Add to order",
        },
      ],
      units: [],
      currencies: [],
      order:{
        userId: this.$route.params.userId,
        currencyId: 1,
        orderItems: []
      },
    }
  },
  async created() {
   await this.getAllProductsList();
   await this.getUnits();
   await this.getCurrencies();
  },
  methods: {
    addProductToOrder(product){
      let orderItem = {
        productId: product.id,
        productName: product.name,
        unitId: 0,
        quantity: 0,
        currencyId: 0,
        offPrice: 0,
        unitPrice: 0,
      }
      this.order.orderItems.push(orderItem);
    },
    async getAllProductsList() {
      try {
        this.overlay = true;
        let _this = this;
        let qParams = {
          count: _this.count,
          pageNumber: _this.pageNumber,
          languageId:1,
        };
        let productGetAll = new ProductGetAll(_this);
        productGetAll.setParams(qParams);
        await productGetAll.fetch((response) => {
          if (response.isSuccess) {
            _this.productsList = response.data.data;
            _this.totalCount = response.data.totalCount;
          }
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.overlay = false;
      }
    },
    async createOrder() {
      let _this = this;
      _this.overlay = true;
      console.log(_this.order);

      let orderCreateByAdmin = new OrderCreateByAdmin(_this);
      orderCreateByAdmin.setParams(_this.order);
      await orderCreateByAdmin.fetch((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        _this.$router.push('/apps/orders/list');
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    async getUnits() {
      let _this = this;
      _this.overlay = true;

      let unitsGetAll = new UnitsGetAll(_this);
      await unitsGetAll.fetch(function (content) {
        _this.overlay = false;
        _this.units = content.data.data;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getCurrencies() {
      let _this = this;
      _this.overlay = true;

      let currenciesGetAll = new CurrenciesGetAll(_this);
      await currenciesGetAll.fetch(function (content) {
        _this.overlay = false;
        _this.currencies = content.data.data;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
  },
  components: {
    ckEditorMain,
    AddEditDeleteButton,
    LanguagePicker,
    BModal,
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    BOverlay,
    vSelect,
    BTable,
    BPagination,
    ValidationObserver, ValidationProvider,
    ProductCategoryProperty,
  },
}
</script>

<style>
.modal-header > .close {
  display: none !important;
}
</style>